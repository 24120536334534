import { render, staticRenderFns } from "./addTransacrionDetails.vue?vue&type=template&id=4ed4e0ef&scoped=true"
import script from "./addTransacrionDetails.vue?vue&type=script&lang=js"
export * from "./addTransacrionDetails.vue?vue&type=script&lang=js"
import style0 from "./addTransacrionDetails.vue?vue&type=style&index=0&id=4ed4e0ef&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed4e0ef",
  null
  
)

export default component.exports